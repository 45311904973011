import React from 'react'

import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Spinner
  } from "reactstrap";

import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

function SendEmailChangePassword() {

    const [email, setEmail] = useState("");  
  
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
  
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();

    ////////////
    const { t, i18n } = useTranslation();  // Destructure i18n from useTranslation

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguageAFS');
        if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);  // Use i18n to change language
        }
    }, [i18n]);

    ////////////

    const handleSubmit  = async (e) => {
        e.preventDefault()

        setLoading(false)

        if (email==='') {
            setMessage(t('The email is empty'))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoading(true)
            return;
        } 

        // check email exist
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/check-email-change-password/${email}/`);
        
            if (response.request.status===200) {
            
            } else {
                setMessage(t("The email doesn't exist in our database!"))
                setError(true)
                setTimeout(function() {
                    setError(false)
                }, 5000);
                setLoading(true)
                return;
            }
            
        } catch (error) {
            setMessage(t("The email doesn't exist in our database!"))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoading(true)
            return;
        }
        
        try {            
            await axios.get(`${process.env.REACT_APP_BACKEND_API}/users/reset-password?email=${email}`, 
                {
                    email: email
                }
            )
            navigate("/email-lost-password-sent/")
        } catch (error) {
            setMessage(t("The email doesn't exist in our database!"))
            setError(true)
            setTimeout(function() {
                setError(false)
            }, 5000);
            setLoading(true)
            return;
        } 
        
    }

    return (
        <>
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                <Row className="justify-content-center">
                    <Col lg="12" md="12">
                    <h1 >AIBIOSAT</h1>
                    <img
                        alt="Automatic Farm Solution"
                        src={"https://i.ibb.co/s5kbrVz/logo-2-senza-background.png"}
                        width={"200px"}
                        height={"200px"}
                    />
                    </Col>
                </Row>
                </div>
                {error && <Alert color="danger" fade={false}>{message}</Alert>}
                <Form role="form">
                <FormGroup className="mb-3">
                    <h4>{t("Change Password")}</h4>
                    <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="ni ni-email-83" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        controlid='email'
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}/>
                    </InputGroup>
                </FormGroup>
                <div className="text-center">
                    {loading ? (
                    <>
                        <Button className="my-4" color="primary" type="button" onClick={handleSubmit}>
                            {t('Send Email')} 
                        </Button>
                    </>
                    ) : (
                    <>
                        <Button color="primary" disabled>
                            <Spinner size="sm">
                            {t('Sending email...')}
                            </Spinner>
                            <span>
                            {' '}{t('Sending email...')}
                            </span>
                        </Button>
                    </>
                    )}
                </div>
                </Form>
                <Row className="mt-3">
                <Col xs="12">
                    <a
                        className="text-dark"
                        href="/"
                        >
                        <small>{t('Go back to Login')} </small>
                    </a>
                </Col>
                </Row>
            </CardBody>
            </Card>
        </Col>
    </>
  )
}

export default SendEmailChangePassword

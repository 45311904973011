import { useState } from "react";
import {  Link } from "react-router-dom";

// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

// var ps;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import {logout} from 'actions/userActions'
import { useSelector } from 'react-redux'; 

import LanguageSelector from '../LanguageSelector'

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
 
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const { logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  /////////////

  const { t } = useTranslation();

  const navigate = useNavigate()  

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    navigate("/")
  }

  const farmerpro = useSelector((state) => state.userLogin.userInfo.is_farmer_pro);

  const [isFarmerPro, setIsFarmerPro] = useState(farmerpro);

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const handleSatelliteClick = () => {
    if (!isFarmerPro) {
      // Utente non pro, mostra il modal
      setIsUpgradeModalOpen(true);
    }
  };

  /////////////

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={"https://i.ibb.co/s5kbrVz/logo-2-senza-background.png"}
            />
            {" "}AIBIOSAT
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-md-none">
          <LanguageSelector/>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={"https://i.ibb.co/vZWYcWx/Sample-User-Icon.png"}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{t('Welcome!')}</h6>
              </DropdownItem>
              <DropdownItem to="/user-profile/" tag={Link}>
                <i className="ni ni-single-02" />
                <span>{t('My profile')}</span>
              </DropdownItem>
              <DropdownItem to="/support/" tag={Link}>
                <i className="ni ni-support-16" />
                <span>{t('Support')}</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="/" onClick={logoutHandler}>
                <i className="ni ni-user-run" />
                <span>{t('Logout')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <>
                      <div style={{ textAlign: "center" }}>
                          <img alt={logo.imgAlt} src={"https://i.ibb.co/s5kbrVz/logo-2-senza-background.png"} /> AIBIOSAT
                      </div>
                    </>
                  ) : (
                    <>
                      <img alt={logo.imgAlt} src={"https://i.ibb.co/s5kbrVz/logo-2-senza-background.png"} /> {" "} AIBIOSAT
                    </>                    
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <h6 className="navbar-heading text-muted">Home</h6>
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/">
                <i className="fa fa-home" />
                  Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/farm">
                <i className="fa-solid fa-building" />
                  {t('Farm')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/field">
                <i className="fa-solid fa-map" />
                  {t('Field')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/upload-field">
                <i class="fa-solid fa-upload"></i>
                {t('Upload Field')}
              </NavLink>
            </NavItem>
          </Nav>

          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">{t('Sensors')}</h6>


          <Nav className="mb-md-3" navbar>      
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/summary">
                    <i className="fa-solid fa-list" />
                    {t('Summary')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-list" />
                  {t('Summary')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/iot-analisys">
                    <i className="fa-solid fa-chart-simple" />
                    {t('Analysis')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-chart-simple" />
                  {t('Analysis')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/iot-anomalies">
                    <i className="fa-solid fa-magnifying-glass-chart" />
                    {t('Anomaly Detection')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-magnifying-glass-chart" />
                  {t('Anomaly Detection')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/iot-comparation">
                  <i class="fa-solid fa-chart-bar"></i> 
                    {t('Comparation')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-chart-bar" />
                  {t('Comparation')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/iot-table">
                    <i className="fa-solid fa-table" />
                    {t('Table')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-table" />
                  {t('Table')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/allert">
                    <i className="fa-solid fa-triangle-exclamation" />
                    {t('Allert')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-triangle-exclamation" />
                  {t('Allert')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {/* <NavItem>
              <NavLink href="/forecast">
                <i className="fa-solid fa-chart-line" />
                {t('Forecast')}
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink href="/bio-trap">
                <i className="fa-solid fa-thumbtack" />
                {t('Bio Trap')}
              </NavLink>
            </NavItem> */}
          </Nav>

          {/* <hr className="my-3" />

          <h6 className="navbar-heading text-muted">{t('Agronomic tools')}</h6>
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/nutrient-balance">
                <i class="fa-solid fa-scale-balanced"></i>
                {t('Nutrient Balance')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/soil-data">
                <i class="fa-solid fa-vials"></i>
                {t('Soil Data')} 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/irrigation">
                <i class="fa-solid fa-droplet"></i>
                {t('Irrigation')} 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/pest-model">
                <i className="ni ni-spaceship" />
                {t('Pest Model')}
              </NavLink>
            </NavItem>
          </Nav> */}

          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">{t('Artificial Intelligence')}</h6>

          <Nav className="mb-md-3" navbar>
            {/* <NavItem>
              <NavLink href="/digital-consulting">
                <i class="fa-solid fa-comments"></i>
                {t('Digital Consulting')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/crop-models">
                <i class="fa-solid fa-location-crosshairs"></i>
                {t('Crop Models & simulations')}
              </NavLink>
            </NavItem>*/}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/carbon-credits">
                    <i class="fa-solid fa-hand-holding-dollar"></i>
                    {t('Soil Mapping & Carbon Credits')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-hand-holding-dollar" />
                  {t('Soil Mapping & Carbon Credits')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )} 
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/organic-vs-convenctional">
                    <i class="fa-solid fa-arrows-left-right-to-line"></i>
                    {t('Organic vs Convenctional')}
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i className="fa-solid fa-arrows-left-right-to-line" />
                  {t('Organic vs Convenctional')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}
            {isFarmerPro ? (
              <>
                <NavItem>
                  <NavLink href="/large-scale">
                    <i class="fa-solid fa-globe"></i>
                    {t('Large Scale Application')}
                    <span className="badge badge-success ml-2">New</span>
                  </NavLink>
                </NavItem>
              </>
            ):(
              <>
                <NavLink onClick={handleSatelliteClick}>
                  <i class="fa-solid fa-globe"></i>
                  {t('Large Scale Application')}
                  <span className="badge badge-danger ml-2">Pro</span>
                </NavLink>
              </>
            )}

          </Nav>

          <hr className="my-3" />

          <h6 className="navbar-heading text-muted">{t('Export Document')}</h6>

          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/export-document">
                <i class="fa-solid fa-file-export"></i>
                {t('Export Document')}
                <span className="badge badge-success ml-2">New</span>
              </NavLink>
            </NavItem>
          </Nav>
          {/* <hr className="my-3" />

          <h6 className="navbar-heading text-muted">{t('Connections & Data Sharing')}</h6>
          
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/integration">
              <i class="fa-solid fa-tractor"></i>
                  Jhon Deere
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/trimble">
              <i class="fa-solid fa-tractor"></i>
                  Trimble
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/class">
              <i class="fa-solid fa-tractor"></i>
                  CLASS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/agcommand">
              <i class="fa-solid fa-tractor"></i>
                  AgCommand
              </NavLink>
            </NavItem>
          </Nav>

          <hr className="my-3" />
        
          <h6 className="navbar-heading text-muted">{t('Upgrade your profile')}</h6>

          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/upgrade-your-profile">
                <i className="ni ni-spaceship" />
                {t('Upgrade your profile')}
              </NavLink>
            </NavItem>
          </Nav> */}
        </Collapse>       
        
        <Modal isOpen={isUpgradeModalOpen} toggle={() => setIsUpgradeModalOpen(false)}>
          <ModalHeader toggle={() => setIsUpgradeModalOpen(false)}>
            {t('Upgrade your account')}
          </ModalHeader>
          <ModalBody>
            <p>{t('If you want to access to this module data, you must upgrade your account to PRO')}</p>
            <Link to="/user-profile">
              <Button color="success" onClick={() => setIsUpgradeModalOpen(false)}>
                {t('Upgrade your account')}
              </Button>
            </Link>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setIsUpgradeModalOpen(false)}>
              {t('Not Now')}
            </Button>
          </ModalFooter>
        </Modal>

      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
